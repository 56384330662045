<template>
	<div class="box box_mm">
		<div class="box_main" style="display: flex;">
			<div>
				<div style="width: 3rem;height: 3rem;border: 1px solid #eee; border-radius: 10px;display: flex;align-items: center;justify-content: center;">
					<div v-if="!userqm" style="color: #ccc;font-size: 0.3rem;">请上传立案章</div>
					<img v-else :src="imageUrl" alt="" style="width: 100%;height: 100%;"/>
				</div>
				<div style="width: 3rem;font-size: 0.18rem;display: flex;align-items: center;justify-content: center;margin-top: 10px;">
					<el-button type="primary" size="mini" @click="showtc=true">编辑</el-button>
				</div>
			</div>
			<div style="margin-left: 0.5rem;">
				<div style="width: 3rem;height: 3rem;border: 1px solid #eee; border-radius: 10px;display: flex;align-items: center;justify-content: center;">
					<div v-if="!userqm2" style="color: #ccc;font-size: 0.3rem;">请上传不予立案</div>
					<img v-else :src="imageUrl2" alt="" style="width: 100%;height: 100%;"/>
				</div>
				<div style="width: 3rem;font-size: 0.18rem;display: flex;align-items: center;justify-content: center;margin-top: 10px;">
					<el-button type="primary" size="mini" @click="showtc2=true">编辑</el-button>
				</div>
			</div>
		</div>
		
		
		<!--审核弹出-->
		<el-dialog title="编辑立案章" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="上传立案章" required>
					  		<el-upload
					  		  class="avatar-uploader"
					  		  :action="baseurl+'/api/login/upload'"
					  		  :show-file-list="false"
					  		  :on-success="handleAvatarSuccess"
					  		  :before-upload="beforeAvatarUpload">
					  		  <img v-if="imageUrl" :src="imageUrl" class="avatar">
					  		  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					  		</el-upload>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="编辑不予立案章" :visible.sync="showtc2" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="上传不予立案章" required>
					  		<el-upload
					  		  class="avatar-uploader"
					  		  :action="baseurl+'/api/login/upload'"
					  		  :show-file-list="false"
					  		  :on-success="handleAvatarSuccess2"
					  		  :before-upload="beforeAvatarUpload">
					  		  <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
					  		  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					  		</el-upload>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren2">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				showtc:false,
				imageUrl:'',
				userid:'',
				userqm:'',
				baseurl: "",
				showtc2:false,
				imageUrl2:'',
				userid2:'',
				userqm2:'',
				baseurl2: "",
			}
		},
		created() {
			this.baseurl = this.$URL
		},
		mounted() {
			this.gettable()
		},
		methods: {
			setsheng(){
				this.showtc = false
				this.showtc2 = false
				this.userqm = ''
				this.userqm2 = ''
				this.gettable()
			},
			queren() {
				this.$post({
					url: '/api/login/xglaz',
					params: {
						id:this.userid,
						tu:this.userqm
					}
				}).then((res) => {
					this.showtc = false
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			queren2() {
				this.$post({
					url: '/api/login/xglaz',
					params: {
						id:this.userid2,
						tu:this.userqm2
					}
				}).then((res) => {
					this.showtc2 = false
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			handleAvatarSuccess2(res, file) {
				console.log(res)
				this.userqm2 = res.id
				this.imageUrl2 = res.fullurl
			},
			handleAvatarSuccess(res, file) {
				console.log(res)
				this.userqm = res.id
				this.imageUrl = res.fullurl
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;
			
				if (!isJPG) {
					this.$message.error('上传图片只能是 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/login/laz',
					params: {
						
					}
				}).then((res) => {
					this.userid = res.id
					this.userqm = res.z?res.z.id:''
					this.imageUrl = res.z?res.z.fullurl:''
				})
				this.$post({
					url: '/api/login/blaz',
					params: {
						
					}
				}).then((res) => {
					this.userid2 = res.id
					this.userqm2 = res.z?res.z.id:''
					this.imageUrl2 = res.z?res.z.fullurl:''
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'lian_zhang.scss';
</style>